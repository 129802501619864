import '../scss/index.scss';

import { Fab } from '@mui/material';
import { Link } from 'gatsby';
import React, { RefObject, useRef } from 'react';
import { Helmet } from 'react-helmet';

import CareerIcon from '../components/careerIcon/CareerIcon';
import FirstPart from '../components/firstPart';
import SecondPart from '../components/secondPart';
import ThirdPart from '../components/thirdPart';

const IndexPage = () => {
  const isBrowser = typeof window !== 'undefined';
  const thirdPart = useRef(null);

  const scrollDown = (scrollRef: RefObject<HTMLInputElement>) => {
    if (scrollRef.current && isBrowser) {
      window.scrollTo({
        behavior: 'smooth',
        top: scrollRef.current.offsetTop,
      });
    }
  };

  return (
    <div className="base-container">
      <Helmet>
        <title>Ancient Gaming - Company that builds the Future of iGaming</title>
        <meta property="og:title" content="Ancient Gaming - Company that builds the Future of iGaming" />
        <meta property="og:description" content={`At ⚡Ancient Gaming 🔥 we develop top-notch online products in iGaming & Gambling niches. We provide a high level of tech support, with a friendly UI & guarantee privacy & fairness for every player.`} />
      </Helmet>
      <FirstPart onClick={() => scrollDown(thirdPart)}></FirstPart>
      <SecondPart />
      <ThirdPart ref={thirdPart}></ThirdPart>
      <div className="careers-fab">
        <Link to="https://careers.ancient.gg">
          <Fab color="primary" aria-label="add">
            <CareerIcon fill="white"></CareerIcon>
          </Fab>
          <span>Careers</span>
        </Link>
      </div>
    </div>
  );
};

export default IndexPage;
