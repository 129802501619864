import React from 'react';
import '../scss/second-part.scss';

import { SiteSectionProps } from '../models/section.model';

const SecondPart = (props: SiteSectionProps) => {
  return (
    <div className="base-container">
      <div className="second-background">
        <div className="content">
          <section>
            <h2>What We Do</h2>
            <p>Ancient.gg specializes in the creation of advanced software solutions integrated with powerful gamification principles to deliver immediate rewards and heightened user engagement. We develop intuitive analytics tools, real-time data insights, and personalized coaching platforms designed to boost performance and enhance user experiences across various sectors. Through strategic use of interactive elements, community-building initiatives, and innovative reward systems, we inspire users and organizations to achieve greater productivity, connection, and success. At Ancient.gg, we merge technology and motivation, pioneering new ways to experience software.</p>
          </section>

          <section>
            <h2>Supporting Services</h2>
            <p>Beyond software development, Ancient.gg offers comprehensive marketing, customer support, and various other supporting functions designed to enhance our platforms. We ensure our products reach their full potential by providing tailored marketing strategies, dedicated customer service, and continuous operational support, delivering a seamless experience to our users worldwide.</p>
          </section>

          <section>
            <h2>Our Story</h2>
            <p>Founded in 2018 in Malta, Ancient.gg has rapidly grown into a thriving organization of more than 150 dedicated individuals. Our diverse team is spread across the globe, uniting talent, creativity, and passion, consistently striving to develop outstanding products and redefine digital engagement. We take pride in our innovative spirit, collaborative culture, and unwavering commitment to excellence.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SecondPart;
